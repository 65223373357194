.main-projects__content {
    display: grid;
    grid-template-rows: 80px 1fr;
    min-height: 100vh;
}

.main-projects {
    display: grid;
    grid-template-columns: .5fr 1fr;
}

.first {
    background: rgba(112, 112, 112, 0);
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
}

.buttonAll {
    width: 100%;
    display: flex;
    cursor: pointer;
}

.buttonAll p {
    margin: 0;
    font-size: 14px;
}

.first__img {
    width: 100%;
}

.grid__img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.second {
    padding: 80px;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttons__project {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 9000;
}

.buttons__project img {
    width: 30px;
    display: block;
}

.git_logo:hover {
    filter: opacity(.7);
}

.ant_next__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    background: none;
    border: 0;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.content__second {
    z-index: 8000;
}

.content__second h2 {
    margin: 0;
    letter-spacing: 3px;
    font-weight: 400;
}

.content__second a {
    color: white;
    font-weight: 500;
}

.content__second p {
    margin: 0;
    letter-spacing: 2px;
    font-weight: 200;
}

.description__git {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.text__repo_cont {
    font-size: 14px;
    padding-bottom: 5px;
    z-index: 9000;
}

.text__repo_cont img {
    width: 35px;
    margin-top: 15px;
    z-index: 9000;
}

.date-repo {
    font-size: small;
    color: tomato;
}

.name-repo {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.view-all {
    position: fixed;
    width: calc(100% - 100px);
    height: calc(100% - 80px);
    padding: 20px;
    padding-bottom: 40px;
    padding-right: 50px;
    background: rgba(0, 0, 0, 0.5);
    /* -webkit-backdrop-filter: saturate(180%) blur(20px); */
    backdrop-filter: saturate(180%) blur(30px);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    animation: animation_all_repos 0.5s forwards;
    z-index: 9000;
}

.down-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.button-down {
    width: 50px;
    display: block;
    cursor: pointer;
}

.down-title {
    margin: 20px;
}

.down-title h2 {
    margin: 0;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
}

.projects-contianer {
    width: 100%;
    height: calc(100% - 125px);
    padding: 30px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 60px;
    justify-content: space-around;
}

.projects-contianer h2 {
    margin: 0;
    letter-spacing: 3px;
    font-weight: 400;
}

.projects-contianer::-webkit-scrollbar {
    width: 10px;
}

.projects-contianer::-webkit-scrollbar {
    background-color: transparent;
}

.projects-contianer::-webkit-scrollbar-thumb {
    background: tomato;
    border-radius: 20px;
}

.buttonAll button {
    background: transparent;
    color: white;
    border: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.container-grid__items {
    height: 100%;
}

.repo__container {
    /* background-image: url("https://images.unsplash.com/photo-1532210317995-cc56d90177d9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"); */
    background: white;
    width: 300px;
    height: 150px;
    border-radius: 5px;
    display: flex;
    letter-spacing: 1px;
    padding: 20px;
    text-decoration: none;
    object-fit: cover;
}

.repo__container:hover {
    box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.253);
}

.title-repo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.title-repo p {
    margin: 0;
}

.back {
    width: 100%;
}

.back img {
    margin: 0;
    width: 100%;
    height: 100%;
    display: block;
}

@media (max-width: 1050px) {
    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .first {
        flex-direction: column-reverse;
        padding-bottom: 0;
    }

    .buttonAll {
        margin-bottom: 20px;
        justify-content: center;
    }

    .main-projects {
        grid-template-columns: none;
        grid-template-rows: .5fr 1fr;
    }

    .second {
        padding: 50px;
    }

    .description__git {
        width: 100%;
    }

    .content__second {
        margin-bottom: 50px;
    }

    .view-all {
        width: 100%;
        padding: 0;
    }
}

@keyframes animation_all_repos {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes animation_all_repos_reverse {
    from {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}