.main-contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact-form {
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    /* background: rgba(108, 109, 110, 0);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(00%) blur(20px); */
    z-index: 1000;
}

.leaflet-container {
    width: 100%;
    height: calc(100vh - 80px);
    background: rgba(108, 109, 110, 0);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(00%) blur(20px);
}

.contact-title {
    font-size: 3rem;
}

.flex-box {
    display: flex;
    margin-bottom: 15px;
}

.input-email, .input-name {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.input-operator {
    color: tomato;
}

.input-email {
    margin-left: 2%;
}

.input-subject, .input-message {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.input-subject {
    margin-bottom: 15px;
}

.input-message {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 150px;
    font-family: 'Poppins', sans-serif;
}

.input-validate {
    width: 20%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
    text-align: center;
}

.valid-send {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.info-text {
    margin-bottom: 50px;
}

.send-button {
    float: right;
    color: white;
    background: transparent;
    font-size: 14px;
    letter-spacing: 3px;
    text-decoration: none;
    width: 100%;
    max-width: 220px;
    line-height: 50px;
    border: 1px solid tomato;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

/* Sweep To Right */

.hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: tomato;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
    color: white;
    cursor: pointer;
}

.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

@media (max-width: 1050px) {
    .main-contact {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
    }

    .contact-form {
        padding-left: 30px;
        padding-right: 30px;
    }

    .flex-box {
        flex-direction: column;
    }

    .input-name {
        margin-bottom: 15px;
    }

    .input-email {
        margin-left: 0;
    }

    .valid-send {
        flex-direction: column;
    }

    .input-validate {
        width: 50%;
    }

    .send-button {
        margin-top: 15px;
    }

    .contact-map {
        margin: 30px;
    }
}