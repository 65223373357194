@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: black;
}

* {
  box-sizing: border-box;
}
.main-sidebar {
    width: 100%;
    height: 100vh;
    padding: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
}

.main-sidebar {
    border-right: 2px solid #707070;
}

.main-sidebar h1 {
    margin: 0;
}

.main-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main-logo img {
    margin-top: 10px;
    width: 30px;
}

.networks {
    line-height: 70px;
}

.networks p {
    transform: rotate(-90deg);
    margin: 0;
}

.networks a {
    transform: rotate(-90deg);
    margin: 0;
    color: white;
    text-decoration: none;
}

.networks a:hover:hover {
    color: tomato;
    cursor: pointer;
}
 .main-navbar {
   padding: 20px;
   padding-right: 50px;
   z-index: 9000;
 }

 .main-navbar__list {
   margin: 0;
   padding: 0;
   width: auto;
   /*Era 50% con icono buscar*/
   min-width: 720px;
   float: right;
   height: 100%;
   list-style: none;
   display: flex;
   align-items: center;
   justify-content: space-between;
 }

 .main-navbar__list_item {
   display: flex;
   align-items: center;
   text-decoration: none;
   color: white;
 }

 .main-navbar__list_item:hover {
   color: tomato;
 }

 .main-navbar__list li {
   display: flex;
   align-items: center;
 }

 .responsive {
   display: none;
 }

 .sidebar-menu {
   width: 300px;
   height: 100vh;
   position: fixed;
   top: 0;
   right: 0px;
   transform: translateX(0);
   transition: 0.3s ease all;
   z-index: 120000;
   animation: animation_nav 0.5s forwards;
   z-index: 9000;
   background: rgb(16, 17, 19);
   display: none;
 }

 .sidebar-menu__content {
   padding: 20px;
   height: 100vh;
 }

 .sidebar-menu__content img {
   width: 35px;
 }

 .sidebar-menu__list {
   list-style: none;
   padding: 0;
   margin-top: 50px;
   margin-bottom: 50px;
   height: 60vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   font-size: 20px;
 }

 .close__item {
   display: flex;
   justify-content: flex-end;
   cursor: pointer;
 }

 .networks__mobile {
   width: 100%;
   padding-left: 50px;
   padding-right: 50px;
   display: flex;
   justify-content: space-between;
 }

 .networks__mobile a {
   text-decoration: none;
   color: white;
 }

 .networks__mobile a:hover {
   text-decoration: none;
   color: tomato;
 }

 .info__content {
   height: calc(100% - 35px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }

 .main-navbar__list_item.selectedNav {
   color: tomato;
   font-weight: 600;
 }

 @media (max-width: 1050px) {
   .main-navbar__list {
     display: none;
   }
   .main-navbar {
     padding-right: 20px;
   }
   .sidebar-menu {
     display: block;
   }
   .responsive {
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 100%;
   }
   .responsive img {
     width: 35px;
   }
   .responsive img:hover {
     cursor: pointer;
   }
   .main-logo__navbar {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
   }
   .main-logo__navbar img {
     width: 30px;
   }
 }

 @keyframes animation_nav {
   from {
     transform: translateX(300px);
   }
   to {
     transform: translateX(0px);
   }
 }

 @keyframes animation_nav_reverse {
   from {
     transform: translateX(0px);
   }
   to {
     transform: translateX(300px);
   }
 }
.main-projects__content {
    display: grid;
    grid-template-rows: 80px 1fr;
    min-height: 100vh;
}

.main-projects {
    display: grid;
    grid-template-columns: .5fr 1fr;
}

.first {
    background: rgba(112, 112, 112, 0);
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
}

.buttonAll {
    width: 100%;
    display: flex;
    cursor: pointer;
}

.buttonAll p {
    margin: 0;
    font-size: 14px;
}

.first__img {
    width: 100%;
}

.grid__img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.second {
    padding: 80px;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttons__project {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 9000;
}

.buttons__project img {
    width: 30px;
    display: block;
}

.git_logo:hover {
    -webkit-filter: opacity(.7);
            filter: opacity(.7);
}

.ant_next__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    background: none;
    border: 0;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.content__second {
    z-index: 8000;
}

.content__second h2 {
    margin: 0;
    letter-spacing: 3px;
    font-weight: 400;
}

.content__second a {
    color: white;
    font-weight: 500;
}

.content__second p {
    margin: 0;
    letter-spacing: 2px;
    font-weight: 200;
}

.description__git {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.text__repo_cont {
    font-size: 14px;
    padding-bottom: 5px;
    z-index: 9000;
}

.text__repo_cont img {
    width: 35px;
    margin-top: 15px;
    z-index: 9000;
}

.date-repo {
    font-size: small;
    color: tomato;
}

.name-repo {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.view-all {
    position: fixed;
    width: calc(100% - 100px);
    height: calc(100% - 80px);
    padding: 20px;
    padding-bottom: 40px;
    padding-right: 50px;
    background: rgba(0, 0, 0, 0.5);
    /* -webkit-backdrop-filter: saturate(180%) blur(20px); */
    -webkit-backdrop-filter: saturate(180%) blur(30px);
            backdrop-filter: saturate(180%) blur(30px);
    transform: translateY(0);
    transition: 0.3s ease all;
    animation: animation_all_repos 0.5s forwards;
    z-index: 9000;
}

.down-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.button-down {
    width: 50px;
    display: block;
    cursor: pointer;
}

.down-title {
    margin: 20px;
}

.down-title h2 {
    margin: 0;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
}

.projects-contianer {
    width: 100%;
    height: calc(100% - 125px);
    padding: 30px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 60px;
    justify-content: space-around;
}

.projects-contianer h2 {
    margin: 0;
    letter-spacing: 3px;
    font-weight: 400;
}

.projects-contianer::-webkit-scrollbar {
    width: 10px;
}

.projects-contianer::-webkit-scrollbar {
    background-color: transparent;
}

.projects-contianer::-webkit-scrollbar-thumb {
    background: tomato;
    border-radius: 20px;
}

.buttonAll button {
    background: transparent;
    color: white;
    border: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.container-grid__items {
    height: 100%;
}

.repo__container {
    /* background-image: url("https://images.unsplash.com/photo-1532210317995-cc56d90177d9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"); */
    background: white;
    width: 300px;
    height: 150px;
    border-radius: 5px;
    display: flex;
    letter-spacing: 1px;
    padding: 20px;
    text-decoration: none;
    object-fit: cover;
}

.repo__container:hover {
    box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.253);
}

.title-repo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.title-repo p {
    margin: 0;
}

.back {
    width: 100%;
}

.back img {
    margin: 0;
    width: 100%;
    height: 100%;
    display: block;
}

@media (max-width: 1050px) {
    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .first {
        flex-direction: column-reverse;
        padding-bottom: 0;
    }

    .buttonAll {
        margin-bottom: 20px;
        justify-content: center;
    }

    .main-projects {
        grid-template-columns: none;
        grid-template-rows: .5fr 1fr;
    }

    .second {
        padding: 50px;
    }

    .description__git {
        width: 100%;
    }

    .content__second {
        margin-bottom: 50px;
    }

    .view-all {
        width: 100%;
        padding: 0;
    }
}

@keyframes animation_all_repos {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes animation_all_repos_reverse {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(100%);
    }
}
.main-contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact-form {
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    /* background: rgba(108, 109, 110, 0);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(00%) blur(20px); */
    z-index: 1000;
}

.leaflet-container {
    width: 100%;
    height: calc(100vh - 80px);
    background: rgba(108, 109, 110, 0);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(00%) blur(20px);
}

.contact-title {
    font-size: 3rem;
}

.flex-box {
    display: flex;
    margin-bottom: 15px;
}

.input-email, .input-name {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.input-operator {
    color: tomato;
}

.input-email {
    margin-left: 2%;
}

.input-subject, .input-message {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.input-subject {
    margin-bottom: 15px;
}

.input-message {
    width: 100%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    font-family: 'Poppins', sans-serif;
}

.input-validate {
    width: 20%;
    border: 0;
    background: #262626;
    height: 50px;
    font-size: 16px;
    color: #8d8d8d;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
    text-align: center;
}

.valid-send {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.info-text {
    margin-bottom: 50px;
}

.send-button {
    float: right;
    color: white;
    background: transparent;
    font-size: 14px;
    letter-spacing: 3px;
    text-decoration: none;
    width: 100%;
    max-width: 220px;
    line-height: 50px;
    border: 1px solid tomato;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

/* Sweep To Right */

.hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
}

.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: tomato;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
    color: white;
    cursor: pointer;
}

.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
    transform: scaleX(1);
}

@media (max-width: 1050px) {
    .main-contact {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
    }

    .contact-form {
        padding-left: 30px;
        padding-right: 30px;
    }

    .flex-box {
        flex-direction: column;
    }

    .input-name {
        margin-bottom: 15px;
    }

    .input-email {
        margin-left: 0;
    }

    .valid-send {
        flex-direction: column;
    }

    .input-validate {
        width: 50%;
    }

    .send-button {
        margin-top: 15px;
    }

    .contact-map {
        margin: 30px;
    }
}
.main-about__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 80px 1fr auto;
    min-height: 100vh;
}

.main-about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 80px);
}

.danielrfimg {
    width: 350px;
    margin-right: 30px;
}

.main-about__container {
    display: flex;
    align-items: center;
    padding: 60px;
}

.main-about__container p {
    max-width: 550px;
}

.gitHubicon {
    width: 40px;
    background: white;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.gitHubicon:hover {
    opacity: 0.7;
}

.facebookicon {
    width: 40px;
    padding: 8px;
    background: #4267B2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facebookicon img {
    display: block;
    margin-right: 0;
}

.flexbox {
    display: flex;
}

.gitHubicon img {
    display: block;
    margin-right: 0;
}

.main-about__info h1 {
    margin-top: 0;
}

.main-about__container_button {
    min-width: 70px;
    display: flex;
    margin-top: 30px;
    z-index: 9000;
}

.main-about__button_contact {
    display: flex;
    align-items: center;
    background: tomato;
    border: 1px solid tomato;
    padding: 10px 15px 10px 15px;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    margin-right: 20px;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    z-index: 9000;
}

.main-about__button {
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid tomato;
    padding: 10px 15px 10px 15px;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.main-about__button img {
    width: 20px;
    margin-left: 0;
    margin-right: 5px;
}

.email-danielrf {
    font-size: 50px;
    color: white;
}

.email-danielrf:hover {
    color: rgba(255, 255, 255, 0.548);
}

.main-about__skills {
    width: 100%;
}

.items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    margin-top: 60px;
}

.main-about__box {
    width: 70%;
    margin: 40px;
    margin-left: auto;
    margin-right: auto;
    background: rgb(16, 17, 19);
    /* color: black; */
    padding: 60px;
    border-radius: 5px;
}

.main-about__box h2 {
    text-align: center;
    letter-spacing: 2px;
    margin: 0;
    color: tomato;
}

.item1 {
    display: flex;
    align-items: center;
    padding: 10px;
}

.imgItem {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.imgItem img {
    height: 100%;
    display: block;
    border-radius: 50%;
}

.textIttem p {
    margin: 0;
}

.descriptionText {
    font-size: small;
}

@media (max-width: 1050px) {
    .danielrfimg {
        width: 200px;
        margin-right: 0px;
    }
    .main-about__container {
        flex-direction: column;
    }
    .main-about__container p {
        font-size: 14px;
    }
    .flexbox {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .main-about__container_button {
        width: 160px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .main-about__button_contact {
        margin-right: 0px;
        justify-content: center;
        margin-bottom: 15px;
    }
    .main-about__container {
        padding: 50px;
    }
    .danielrfimg {
        margin-bottom: 30px;
    }
    .items {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: repeat(1fr, 4);
        grid-gap: 60px;
        margin-top: 60px;
    }
}

@media (max-width: 550px) {
    .main-about__box {
        width: 100%;
        margin: 40px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }
}
.main-home__grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-areas: "sidebar content";
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-home__sidebar {
    grid-area: sidebar;
}

.main-home__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 80px 1fr;
    height: 100vh;
}

.main-home__container_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 220px;
}

.informaion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "actual presentation";
}

.event {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.actual_proyect {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.line_porcent {
    width: 200px;
    height: 2px;
    background: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
}

.actual_porcent {
    height: 100%;
    background-color: tomato;
}

.last_name {
    font-weight: 200;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.445);
}

.actual_proyect p {
    margin: 0;
}

.presentation {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.presentation h1 {
    font-size: 3rem;
    margin: 0;
}

.textinformation {
    width: 100%;
    max-width: 400px;
}

.liked {
    background: rgba(16, 17, 19, 0);
    padding: 20px;
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
}

.public__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.proyectView {
    background: rgba(16, 17, 19, 0);
    display: flex;
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
}

.imgProyect {
    background: white;
    display: flex;
    align-items: center;
}

.imgProyect img {
    width: 180px;
    display: block;
    margin: 20px;
}

.informationProyect {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    margin: 0;
    letter-spacing: 2px;
}

.description {
    font-size: 14px;
    font-weight: 300;
    padding-top: 20px;
}

.liked img {
    width: 50px;
    height: 100%;
    margin-right: 15px;
}

.public {
    display: flex;
}

.public p {
    margin: 0;
    font-size: 14px;
}

.post {
    font-weight: 200;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.post p {
    margin-right: 10px;
}

.titleRepo {
    font-weight: 400;
}

.dateRepo {
    padding-top: 5px;
    font-weight: 200;
}

.read_More {
    color: white;
    text-decoration: none;
}

.read_More:hover {
    color: tomato;
}

@media (max-width: 1050px) {
    .presentation {
        padding: 0;
        margin: 40px;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .actual_proyect {
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: 40px;
        margin-top: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
    }

    .main-home__grid {
        grid-template-columns: 1fr;
        grid-template-areas: "content";
    }

    .main-home__sidebar {
        display: none;
    }

    .main-home__container_info {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: none;
    }

    .liked, .proyectView {
        display: none;
    }
}

@media (max-width: 568px) {
    .presentation h1 {
        font-size: 1.5rem;
    }

    .presentation p {
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .presentation h1 {
        font-size: 1.5rem;
    }
}
