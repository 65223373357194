 .main-navbar {
   padding: 20px;
   padding-right: 50px;
   z-index: 9000;
 }

 .main-navbar__list {
   margin: 0;
   padding: 0;
   width: auto;
   /*Era 50% con icono buscar*/
   min-width: 720px;
   float: right;
   height: 100%;
   list-style: none;
   display: flex;
   align-items: center;
   justify-content: space-between;
 }

 .main-navbar__list_item {
   display: flex;
   align-items: center;
   text-decoration: none;
   color: white;
 }

 .main-navbar__list_item:hover {
   color: tomato;
 }

 .main-navbar__list li {
   display: flex;
   align-items: center;
 }

 .responsive {
   display: none;
 }

 .sidebar-menu {
   width: 300px;
   height: 100vh;
   position: fixed;
   top: 0;
   right: 0px;
   -webkit-transform: translateX(0);
   transform: translateX(0);
   -webkit-transition: 0.3s ease all;
   transition: 0.3s ease all;
   z-index: 120000;
   animation: animation_nav 0.5s forwards;
   z-index: 9000;
   background: rgb(16, 17, 19);
   display: none;
 }

 .sidebar-menu__content {
   padding: 20px;
   height: 100vh;
 }

 .sidebar-menu__content img {
   width: 35px;
 }

 .sidebar-menu__list {
   list-style: none;
   padding: 0;
   margin-top: 50px;
   margin-bottom: 50px;
   height: 60vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   font-size: 20px;
 }

 .close__item {
   display: flex;
   justify-content: flex-end;
   cursor: pointer;
 }

 .networks__mobile {
   width: 100%;
   padding-left: 50px;
   padding-right: 50px;
   display: flex;
   justify-content: space-between;
 }

 .networks__mobile a {
   text-decoration: none;
   color: white;
 }

 .networks__mobile a:hover {
   text-decoration: none;
   color: tomato;
 }

 .info__content {
   height: calc(100% - 35px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }

 .main-navbar__list_item.selectedNav {
   color: tomato;
   font-weight: 600;
 }

 @media (max-width: 1050px) {
   .main-navbar__list {
     display: none;
   }
   .main-navbar {
     padding-right: 20px;
   }
   .sidebar-menu {
     display: block;
   }
   .responsive {
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 100%;
   }
   .responsive img {
     width: 35px;
   }
   .responsive img:hover {
     cursor: pointer;
   }
   .main-logo__navbar {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
   }
   .main-logo__navbar img {
     width: 30px;
   }
 }

 @keyframes animation_nav {
   from {
     -webkit-transform: translateX(300px);
     transform: translateX(300px);
   }
   to {
     -webkit-transform: translateX(0px);
     transform: translateX(0px);
   }
 }

 @keyframes animation_nav_reverse {
   from {
     -webkit-transform: translateX(0px);
     transform: translateX(0px);
   }
   to {
     -webkit-transform: translateX(300px);
     transform: translateX(300px);
   }
 }