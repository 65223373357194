.main-sidebar {
    width: 100%;
    height: 100vh;
    padding: 20px;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
}

.main-sidebar {
    border-right: 2px solid #707070;
}

.main-sidebar h1 {
    margin: 0;
}

.main-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main-logo img {
    margin-top: 10px;
    width: 30px;
}

.networks {
    line-height: 70px;
}

.networks p {
    transform: rotate(-90deg);
    margin: 0;
}

.networks a {
    transform: rotate(-90deg);
    margin: 0;
    color: white;
    text-decoration: none;
}

.networks a:hover:hover {
    color: tomato;
    cursor: pointer;
}