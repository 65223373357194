.main-home__grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-areas: "sidebar content";
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-home__sidebar {
    grid-area: sidebar;
}

.main-home__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 80px 1fr;
    height: 100vh;
}

.main-home__container_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 220px;
}

.informaion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "actual presentation";
}

.event {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.actual_proyect {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.line_porcent {
    width: 200px;
    height: 2px;
    background: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
}

.actual_porcent {
    height: 100%;
    background-color: tomato;
}

.last_name {
    font-weight: 200;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.445);
}

.actual_proyect p {
    margin: 0;
}

.presentation {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.presentation h1 {
    font-size: 3rem;
    margin: 0;
}

.textinformation {
    width: 100%;
    max-width: 400px;
}

.liked {
    background: rgba(16, 17, 19, 0);
    padding: 20px;
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
}

.public__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.proyectView {
    background: rgba(16, 17, 19, 0);
    display: flex;
    -webkit-backdrop-filter: saturate(100%) blur(5px);
    backdrop-filter: saturate(100%) blur(5px);
}

.imgProyect {
    background: white;
    display: flex;
    align-items: center;
}

.imgProyect img {
    width: 180px;
    display: block;
    margin: 20px;
}

.informationProyect {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    margin: 0;
    letter-spacing: 2px;
}

.description {
    font-size: 14px;
    font-weight: 300;
    padding-top: 20px;
}

.liked img {
    width: 50px;
    height: 100%;
    margin-right: 15px;
}

.public {
    display: flex;
}

.public p {
    margin: 0;
    font-size: 14px;
}

.post {
    font-weight: 200;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.post p {
    margin-right: 10px;
}

.titleRepo {
    font-weight: 400;
}

.dateRepo {
    padding-top: 5px;
    font-weight: 200;
}

.read_More {
    color: white;
    text-decoration: none;
}

.read_More:hover {
    color: tomato;
}

@media (max-width: 1050px) {
    .presentation {
        padding: 0;
        margin: 40px;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .actual_proyect {
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: 40px;
        margin-top: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
    }

    .main-home__grid {
        grid-template-columns: 1fr;
        grid-template-areas: "content";
    }

    .main-home__sidebar {
        display: none;
    }

    .main-home__container_info {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: none;
    }

    .liked, .proyectView {
        display: none;
    }
}

@media (max-width: 568px) {
    .presentation h1 {
        font-size: 1.5rem;
    }

    .presentation p {
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .presentation h1 {
        font-size: 1.5rem;
    }
}