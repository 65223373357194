.main-about__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 80px 1fr auto;
    min-height: 100vh;
}

.main-about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 80px);
}

.danielrfimg {
    width: 350px;
    margin-right: 30px;
}

.main-about__container {
    display: flex;
    align-items: center;
    padding: 60px;
}

.main-about__container p {
    max-width: 550px;
}

.gitHubicon {
    width: 40px;
    background: white;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.gitHubicon:hover {
    opacity: 0.7;
}

.facebookicon {
    width: 40px;
    padding: 8px;
    background: #4267B2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facebookicon img {
    display: block;
    margin-right: 0;
}

.flexbox {
    display: flex;
}

.gitHubicon img {
    display: block;
    margin-right: 0;
}

.main-about__info h1 {
    margin-top: 0;
}

.main-about__container_button {
    min-width: 70px;
    display: flex;
    margin-top: 30px;
    z-index: 9000;
}

.main-about__button_contact {
    display: flex;
    align-items: center;
    background: tomato;
    border: 1px solid tomato;
    padding: 10px 15px 10px 15px;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    margin-right: 20px;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    z-index: 9000;
}

.main-about__button {
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid tomato;
    padding: 10px 15px 10px 15px;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.main-about__button img {
    width: 20px;
    margin-left: 0;
    margin-right: 5px;
}

.email-danielrf {
    font-size: 50px;
    color: white;
}

.email-danielrf:hover {
    color: rgba(255, 255, 255, 0.548);
}

.main-about__skills {
    width: 100%;
}

.items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    margin-top: 60px;
}

.main-about__box {
    width: 70%;
    margin: 40px;
    margin-left: auto;
    margin-right: auto;
    background: rgb(16, 17, 19);
    /* color: black; */
    padding: 60px;
    border-radius: 5px;
}

.main-about__box h2 {
    text-align: center;
    letter-spacing: 2px;
    margin: 0;
    color: tomato;
}

.item1 {
    display: flex;
    align-items: center;
    padding: 10px;
}

.imgItem {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.imgItem img {
    height: 100%;
    display: block;
    border-radius: 50%;
}

.textIttem p {
    margin: 0;
}

.descriptionText {
    font-size: small;
}

@media (max-width: 1050px) {
    .danielrfimg {
        width: 200px;
        margin-right: 0px;
    }
    .main-about__container {
        flex-direction: column;
    }
    .main-about__container p {
        font-size: 14px;
    }
    .flexbox {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .main-about__container_button {
        width: 160px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .main-about__button_contact {
        margin-right: 0px;
        justify-content: center;
        margin-bottom: 15px;
    }
    .main-about__container {
        padding: 50px;
    }
    .danielrfimg {
        margin-bottom: 30px;
    }
    .items {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: repeat(1fr, 4);
        grid-gap: 60px;
        margin-top: 60px;
    }
}

@media (max-width: 550px) {
    .main-about__box {
        width: 100%;
        margin: 40px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }
}